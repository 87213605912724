@import '../../../mixins.scss';

.ContentBorder {
  border: 10px solid;
  border-image-slice: 1 !important;
  border-width: 5px;
  border-image-source:  linear-gradient(326deg, rgba(183,150,113,1) 0%, rgba(112,90,49,1) 30%, rgba(255,243,220,1) 67%, rgba(118,98,57,1) 100%);//linear-gradient(to left, #b79671, #d53a9d);
  position: relative;
  z-index: 9;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding-bottom: 40px;
  @include for-breakpoint(lg) {
    // width: 95%;
  }
  // @include for-breakpoint(md) {
  //   width: 95%;
  // }
  @media screen and (max-width: 956px) {
    width: 95%
  }
}

.dotGrid {
  width: 100px;
  height: 100px;
  // background:  linear-gradient(270deg, rgba(108,197,201,1) 0%, rgba(247,15,255,1) 100%);
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(-40%,-48%,0);
  // filter: drop-shadow(-3px -3px 0px rgba(0 0 0, 1));
  // opacity: 0.7;
  
  @include for-breakpoint(lg) {
      transform: translate3d(0%, -48%, 0);    
  }
}

.hangInThere {
  position: absolute;
  bottom: 34px;
  transform: translateY(100%);
  width: 176.15;
  height: 255.45px;
  left: 22px;
  animation: swangin 2s infinite ease-in-out alternate-reverse;
  transform-origin: center 13.5%;
  filter: drop-shadow(10px 14px 5px rgba(0,0,0,0.4));
}

@keyframes swangin {
  0% {
    transform: translateY(100%) rotate(-1deg);
  }
  100% {
    transform: translateY(100%) rotate(1deg);
  }
}


