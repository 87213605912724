.ExpandedPostModal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
}

.closeBtn {
  z-index: 9;
  width: 40px;
  position: fixed;
  top: 0px;
  right: 18px;
  cursor: pointer;
  background: rgba(255,255,255,0.8);
  padding: 5px;
  border-radius: 10px;
  transition: background-color 0.7s;
  &:hover {
    background: rgba(255,255,255,1);
  }
}