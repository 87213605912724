.WelcomeBack{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img {
    width: 300px;
    margin-top: 25px;
  }
  h3 {
    margin-top: 55px;
    font-size: 1.45em;
  }
  button {
    border: solid 1px rgba(255,255,255,0.5);
    background: transparent;
    color: rgba(255,255,255,0.7);
    transition: color 0.5s, border 0.5s;
    padding: 0.75em 2em;
    margin: 25px 1em 0;
    cursor: pointer;
    &:hover {
      color:rgba(255,255,255,1);
      border: solid 1px rgba(255,255,255,1)
    }
    &:first-child {
      // margin-left: -1em;
    }
  }
}