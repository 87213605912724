@import '../../../mixins.scss';
.wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  z-index: 21;
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    opacity: 0;
    animation-fill-mode: forwards;
    transform: scaleX(-1);
  }
  img {
    width: auto;
    max-height: 80vh;
    @include for-breakpoint(sm) {
      max-height: unset;
      width: 100vw;
    }
  }
}

.openingVid {
  height: 90vh;
  width: auto;
  animation-fill-mode: forwards;
  animation: fadein 1s 0.5s 1;
}

.openingVidDone {
  composes: openingVid;
  animation: fadeout 1s 1;
}

@keyframes fadein {
  0% {opacity: 0}
  100% {opacity:1}
}

@keyframes fadeout {
  0% {opacity: 1}
  100% {opacity:0}
}