@import '../mixins.scss';
// .SharedContentWrapper {
//   // margin: 0 5px;
//   padding: calc(2.3rem - 5px) 2.9rem;
//   min-width: calc(100% - 6rem - 11px);
//   min-height: 100%;
//   h1 {
//     margin: 0 0 1.6rem 0;
//     color: #000;
//     font-family: arboria, sans-serif;
//     font-weight: 400;
//     font-style: normal;
//     font-size: 2.68rem;
//   }
// }

.layoutWrapper {
  font-family: arboria, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #000;
  position: relative;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
  @include for-breakpoint(lg) {
    margin-bottom: 1.5rem;
  }
  &:after {
    content: '';
    width: 50%;
    height: 1px;
    background: #000;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    position: absolute;
  }
}
.layoutTitle {
  h2 {
    display: inline-block;
    margin-right: 2.5em;
    margin-top: 0;
  }
  @include for-breakpoint(lg) {
    margin: 0 15px;
  }
}
.bodyText {
  font-size: 1.2rem;
  line-height: 1.4rem;
  @include for-breakpoint(lg) {
    margin: 5px 15px;
  }
}
.readMoreLink {
  color: #00aeff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.scrollWrap{
  display:block
}

.loadWrapper {
  width: 100%;
  text-align: center;
  min-height: 100px;
}

.postSeparator {
  width: 50px;
  height: 1px;
  background: #999;
  display: block;
  margin: 0 auto 10px auto;
}