@import '../../../mixins.scss';
.MainButtonOverlay{
  display: flex;
  flex-direction: column;
  z-index: 999;
  transform: translate3d(0,0,0);
  position: absolute;
  top: 30px;
  right: 40px;
  opacity: 1;
  transition: opacity 5s;
  @include for-breakpoint(md) {
    right: 0;
  }
  button {
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 999px;
    margin: 20px;
    border: solid 1px rgba(255,0,168);
    box-shadow: 7px 14px 25px 0px rgba(0,0,0,0.15),-9px 11px 15px 0px rgba(255,0,168,0.27);
    cursor: pointer;
    
    img {
      width: 80%;
      position: relative;
      top: 1px;
    }
    &:nth-child(1) {
      animation: shadowWaver1 6s infinite;
    }
    &:nth-child(2) {
      border: solid 1px rgba(0,222,255);
      animation: shadowWaver2 6s infinite;
      // animation: shadowWaver1 6s infinite -2s;
      img {
        width: 70%;
        position: relative;
        top: 1px;
      }
    }
  }
}
.disabledMainButtonOverlay{
  composes: MainButtonOverlay;
  pointer-events: none;
}
.MainButtonOverlayHidden{
  composes: MainButtonOverlay;
  opacity: 0;
}

.hiddenButton {
  animation: buttonFadeout 0.5s !important;
  animation-fill-mode: forwards !important;
  pointer-events: none;
}

@keyframes buttonFadeout {
  0% {opacity: 1}
  100% {opacity: 0}
}

@keyframes shadowWaver1 {
  0% {box-shadow: 7px 14px 25px 0px rgba(0,0,0,0.15),-14px 14px 29px 0px rgba(255,0,168,0.3);}
  26% {box-shadow: 7px 14px 25px 0px rgba(0,0,0,0.15),-15px 4px 29px 0px rgba(255,0,168,0.3);}
  49.5% {box-shadow: 7px 14px 25px 0px rgba(0,0,0,0.15),9px -11px 15px 0px rgba(255,0,168,0.27);}
  26% {box-shadow: 7px 14px 25px 0px rgba(0,0,0,0.15),4px -8px 29px 0px rgba(255,0,168,0.3);}
  100% {box-shadow: 7px 14px 25px 0px rgba(0,0,0,0.15),-14px 14px 29px 0px rgba(255,0,168,0.3);}
}

@keyframes shadowWaver2 {
  0% {box-shadow: 7px 14px 25px 0px rgba(0,0,0,0.15),-14px 14px 29px 0px rgba(0,222,255,0.3);}
  26% {box-shadow: 7px 14px 25px 0px rgba(0,0,0,0.15),-15px 4px 29px 0px rgba(0,222,255,0.3);}
  49.5% {box-shadow: 7px 14px 25px 0px rgba(0,0,0,0.15),9px -11px 15px 0px rgba(0,222,255,0.27);}
  26% {box-shadow: 7px 14px 25px 0px rgba(0,0,0,0.15),4px -8px 29px 0px rgba(0,222,255,0.3);}
  100% {box-shadow: 7px 14px 25px 0px rgba(0,0,0,0.15),-14px 14px 29px 0px rgba(0,222,255,0.3);}
}