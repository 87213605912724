.HubScroll {
  width: 30px;
  // height: 100%;
  // background: #f00;
  position: absolute;
  top: 45px;
  right: 0;
  bottom: 50px;
  left: calc(100% - 30px);
  z-index: 11;
  background: rgba(0,0,0,0.1);
}

.HubScrollWrapper {
  height: calc(100% - (8rem) - 20px);
  top: 8rem;
  right: calc(50% - 520px);
  width: 30px;
  position: absolute;
  @media screen and (max-width: 1069px) {
    display: none;
  }
}

.scrollThumb {
  display: block;
  width: 30px;
  height: 35px;
  position: absolute;
  bottom: 59%;
  left: 0;
  cursor: pointer;
  background-color: rgba(0,0,0,0.8);
  mask-image: url('/assets/img/scrollbarMask.png');
  mask-size: 100% auto;
  mask-position: 2px 2px;
  z-index: 9;
}

.scrollThumbInner {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(85deg, rgba(0,212,255,1) 0%, rgba(247,15,255,1) 100%);
  mask-image: url('/assets/img/scrollbarMask.png');
  mask-size: 100% auto;
  z-index: 5;
}

.scrollTrack {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background: #5551e8;
  opacity: 0.72;
}

.hubScrollButton {
  width: 40px;
  height: 35px;
  background: url('/assets/img/upTri.svg');
  background-repeat: none;
  position: absolute;
  left: -4px;
  z-index: 4;
  cursor: pointer;
}

.hubScrollButtonTop {
  composes: hubScrollButton;
  top: 0;
  bottom: auto;
}

.hubScrollButtonBottom {
  composes: hubScrollButton;
  top: auto;
  bottom: 0;
  transform: scaleY(-1);
  polygon:nth-child(1),polygon:nth-child(2) {
    fill: #00fcff;
  }
}