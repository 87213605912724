.Two {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: "arboria",sans-serif;
  height: 100vh;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  >img {
    width: 100%;
    height: auto;
  }
  >p{
    text-align: center;
    padding: 0 1em;
  }
}

.emailLabel {
  // display: block;
  margin-bottom: 0.7em;
}
.emailInput{
  font-size: 1.1em;
  padding: 5px;
}

.mcFieldGroup{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mcSubmitGroup{
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscribeBtn {
  font-family: "arboria",sans-serif;
  border: solid 1px rgba(255,255,255,0.5);
  background: transparent;
  color: rgba(255,255,255,0.7);
  transition: color 0.5s, border 0.5s;
  padding: 0.5em 2em 0.6em 2em;
  margin: 1.5em 0 0;
  font-size: 1.05em;
  cursor: pointer;
  &:hover {
    color:rgba(255,255,255,1);
    border: solid 1px rgba(255,255,255,1)
  }
  &:first-child {
    // margin-left: -1em;
  }
}