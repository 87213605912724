@import '../../../mixins.scss';
.ContentOverlay {
  background: #fff;
  width: 900px;
  // min-height: 70vh;
  margin: 17px;
  z-index: 10;
  @include for-breakpoint(md) {
    outline: none;
  }
}