@import '../../../mixins.scss';
.GroundPlane {
  position: fixed;

  bottom: -4px;
  left: 0;
  z-index: 3;
  img {
    width: 100vw;
    height: auto;
  }
}

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
  // padding-bottom: 50vh;
  
}

.scrollOverflowWrapper {
  margin: 5px;
  overflow: hidden;
  @include for-breakpoint(lg){
    margin: 0;
  }
  // position: absolute;
  // top: 2px;
  // left: 2px;
  // right: 2px;
  // bottom: 2px;
  // overflow: hidden;
}



.SharedContentWrapper {
  // margin: 0 5px;
  padding: calc(2.3rem - 5px) 2.9rem;
  min-width: calc(100% - 6rem - 11px);
  min-height: 100%;
  will-change: transform;
  transform: translate3d(0,0px,0);
  h1 {
    margin: 0 0 1.6rem 0;
    color: #000;
    font-family: arboria, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.68rem;
    @include for-breakpoint(lg) {
      margin-left: 15px;
    }
  }
  @include for-breakpoint(md) {
    padding: 0 0 10px 0;
  }
}

.layoutWrapper {
  font-family: arboria, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #000;
  position: relative;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
  &:after {
    content: '';
    width: 50%;
    height: 1px;
    background: #000;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    position: absolute;
  }
}
.layoutTitle {
  h2 {
    display: inline-block;
    margin-right: 2.5em;
  }
}
.bodyText {
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.readMoreLink {
  color: #00aeff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.scrollWrap{
  display:block;
  position: relative;
}