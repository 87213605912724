html, body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: kudryashev-d-excontrast-sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  background: #000;
}

body {
  position: fixed;
  overflow: hidden;
}


#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  color: #fff;
}

html.mmbScrolling{
  cursor: all-scroll;
}
body.mmbScrolling {
  pointer-events: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MMBStartMarker {
  position: absolute;
  width: 50px;
  height: 50px;
  /* border: solid 1px rgba(0,0,0,0.4); */
  z-index: 999;
  transform: translate3d(-50%,-50%,0);
  border-radius: 100rem;
  background-image: url('assets/img/ouroboros1.svg');
  background-size: 90px 90px;
  background-position: -20px -24px;
  opacity: 0.3;
}