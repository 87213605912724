.outerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.wrapper {
  display: inline-block;
  position: relative;
  > canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: screen;
    -webkit-tap-highlight-color: transparent;
  }
  // > img {
  //   opacity: 0.1;
  // }
}

.hover {
  cursor: pointer;
}