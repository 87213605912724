@import '../../mixins.scss';

.ExpandedPostContent {
  color: #000;
  width: 900px;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 70px;
  font-family: "arboria", sans-serif;
  font-size: 1.2em;
  @include for-breakpoint(md){
    width: 100%;
    font-size: 1.1em;
  }
  img {
    max-width: 100%;
  }
  h1,h2,h3,h4,h5,h6,p {
    padding: 0 20px;
    user-select: text;
  }
  li,blockquote {
    user-select: text;
  }
}

.stickyHeader {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: -15px;
  background: rgba(255,255,255,0.9);
  padding-right: 20px;
  z-index: 2;
  border-radius: 0 0 27px 0px;
  transition: background-color 0.7s;
  &:hover {
    background: rgba(255,255,255,1);
  }
  
}

.ExpandedPostTitle{
  margin: 10px 10px 10px 0;
  padding: 0 20px 0 30px !important;
  > svg {
    width: 45px;
    height: 25px;
    position: relative;
    top: 6px;
    border: solid 1px #000;
    border-radius: 14px;
    padding: 3px;
    opacity: 0.4;
    transition: opacity 0.5s, stroke 0.5s, fill 0.5s, border-color 0.5s;
    cursor: pointer;
    margin-left: 25px;
    &:hover{
      opacity: 1;
      stroke: #00aeef;
      fill: #00aeef;
      border-color: #00aeef;
    }
  }
}

.ExpandedPostContentDate {
  display: block;
  margin-left: 23px;
}

.ExpandedPostPreviewShareBar{
  background: #00aeef;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: translateX(-88%);
  input {
    z-index: 1000;
    display: block;
    background: #fff;
    position: relative;
    border: solid 1px #000;
    padding: 6px;
    margin: 10px;
    margin-right: 2px;
  }
  svg {
    background: #fff;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    cursor: pointer;
  }
}

.clipboardCopiedIndicator {
  display: block;
  width: 100%;
  position: absolute;
  top: -1em;
  background: #ec008c;
  text-align: center;
  font-weight: 700;
}