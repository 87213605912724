.PostLoadAnim {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 60px;
  // position: absolute;
  // top: 40%;
  // left: 50%;
  // transform: translate3d(-50%,-50%,0);
  h3 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    letter-spacing: 0.06em;
    font-family: "microgramma-extended", sans-serif;
    font-weight: 100;
    font-style: normal;
    opacity: 0.6;
    position: relative;
    top: 40px;
    text-align: center;
  }
}


.LoadTile {
  // color: #ff9000;
  width: 100px;
  height: 100px;
  position: relative;
  &:before, &:after {
    background: rgba(0,0,0,0.6);
    // background: rgb(255,218,0);
    // background: linear-gradient(0deg, rgba(255,180,0,0.7) 0%, rgba(255,144,0,1) 100%);
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    transform-origin: 50% 100%;	
    clip-path: polygon(0 0,100% 0,50% 100%);
    -webkit-clip-path: polygon(0 0,100% 0,50% 100%);
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  &:before {
    animation-name: fadeA;
  }
  &:after {
    animation-name: fadeB;
  }
}

@keyframes fadeA {
	from, to { opacity: 1; transform: rotate(0deg) }
	25%, 75.1% { opacity: 0.1; transform: rotate(0deg) }
	25.1%, 75% { opacity: 0.1; transform: rotate(180deg) }
	50% { opacity: 1; transform: rotate(180deg) }
}
@keyframes fadeB {
	from, 50% { opacity: 0.1; transform: rotate(90deg) }
	25% { opacity: 1; transform: rotate(90deg) }
	50.1%, to { opacity: 0.1; transform: rotate(270deg) }
	75% { opacity: 1; transform: rotate(270deg) }
}


