.wrapper {
  margin: 0;
  padding: 0;
  position: relative;
  > img, > svg {
    height: 100vh;
    width: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%,-50%,0);
    @media (orientation: portrait) {
      width: 150vw;
      height: auto;
    }
    z-index: 1;
  }
  svg {
    display: block;
    z-index: 10;
    mix-blend-mode: screen;
    pointer-events: visible;
  }
}
.briefcaseRender {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  @media (orientation: landscape) {
    height: 95vh;
    width: auto;
  }
  z-index: 5;
  transform: translateZ(0)
}


.outline {
  stroke: none;
  stroke-width: 2px;
  cursor: grab;
  &:hover{
    stroke: rgba(255,255,255, 0.5);
    stroke-width: 2.5px;
    stroke-dasharray: 160;
    animation: stroke-crawl-hover 40s infinite forwards linear;
  }
  &[data-selected="true"] {
    stroke: rgba(255, 144, 0, 0.8);
    stroke-width: 5px;
    stroke-dasharray: 160;
    animation: stroke-crawl-selected 40s infinite forwards linear;
  }
}

.grabberOutline, .diceOutline, .cardOutline, .cannonOutline, .blasterOutline {
  composes: outline;
}

.cardOutline {
  cursor: zoom-in;
}
.diceSet {
  cursor: zoom-in;
  polygon {
    opacity: 0
  }
}
.diceSet:hover {
  path {
    animation: glowpulse1 1.2s infinite alternate ease-out;
  }
}

.closeMask {
  fill: none;
  cursor: url('../../../assets/cursor/goBackCursor20.png'), auto;
}

.briefcaseOverlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.7);
  // transform: translate(-50%,-50%);
  z-index: -1;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABvUlEQVQ4je2UwaraUBCGv+OoASlKRAot4qLLgrs+QbduXCi4dKHPUYiBFlwKt48g7vsGPkJfoCjaRWyjIBrUZLrQSOSa9vZ22x8Cc2bOfJmQc36jqgAYY4jjWMaYRqPR+NJsNgnD8KYmIszncxzH+aCqzrWgqldQHCdyjuM4mqbZbKbALNmT5fcKk5MdDgeiKMIYg4iwWq0AVsmGPwGjKIoA8DyPXq/HYrFARBARttstwOe/AV4VBAHT6RTf998Bcnm2wNdnATOZDKVSCd/3f6jqtzhvjLnd91Tgk1/8H/jPuvnLxhgbyAG7S+p4PB6B8y0KggAgMMa8APKcj46XBrRt2/6Zy+XY7c680+lEoVA4f0omQ7lcZrPZfBcR8vk8IoLneS+T0CSwXq/XcV0X27YJgoAwDKlWqwBUKhXG4zH7/R7Lsshms7iuy2QyeQ9MrpSEEViWZWm/3081g6RGo5EWi0UFijeGknQbwAIe2u22bjabVJjjOAo8AG+TQz0CJuJPrVbrLnQwGMSwV/dsL9UPY+h6vb4He53al1a45D52Oh1dLpc6HA4fwZ4DFBHRbrertVpNgTd39tysfwEPIs6IkrfOOAAAAABJRU5ErkJggg==), auto;
}



@keyframes glowpulse-selected {
  0% {
    filter: drop-shadow( 0px 0px 3px rgba(255, 176, 83, 0.9));
  }
  100% {
    filter: drop-shadow( 0px 0px 30px rgba(255, 176, 83, 0.9));
  }
}

@keyframes glowpulse-hover {
  0% {
    filter: drop-shadow( 0px 0px 5px rgba(255, 246, 143, 0.55));
  }
  100% {
    filter: drop-shadow( 0px 0px 10px rgba(255, 246, 143, 0.55));
  }
}

@keyframes stroke-crawl-selected {
  0% {
    stroke-dashoffset: 99.99%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes stroke-crawl-hover {
  0% {
    stroke-dashoffset: 99.99%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}