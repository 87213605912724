.IOSPermissionButton {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  z-index: 10;
  background: #fff;
}

.body {
  h3 {
    color: #000;
    text-align: center;
    letter-spacing: 0.2px;
  }
}

.buttonGang {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    width: 45%;
    height: 40px;
  }
}