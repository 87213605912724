$hangTime: 500ms;
$delayTime: 300ms;
$animDuration: 1500ms;

.BlaBlaDialogueRendererWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgba(255,255,255,0.7);
  // padding-bottom: 30vh;
}

.BlaBlaButton {
  border: solid 1px rgba(255,255,255,0.5);
  background: transparent;
  color: rgba(255,255,255,0.7);
  transition: color 0.5s, border 0.5s;
  padding: 0.75em 2em;
  margin: 0 1em;
  cursor: pointer;
  &:hover {
    color:rgba(255,255,255,1);
    border: solid 1px rgba(255,255,255,1)
  }
  &:first-child {
    // margin-left: -1em;
  }
}

.BlaBlaFadeInRendererWrapper {
  opacity: 1;
}

.BlaBlaDialogueOutputWrapper{
  opacity:1;
  > div[data-purpose='groupFadeWrapper'] {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translate3d(0,0,0);
  }
}

.BlaBlaDialogueInputWrapper{
  width: 100%;
  margin-top: 2.4em;
  padding-bottom: 30vh;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // > div {
    //   > button {
    //     margin: 1em;
    //     color: rgba(255,255,255,0.8);
    //     background: none;
    //     border: solid 1px rgba(255,255,255,0.8);
    //     padding: 1em 2em;
    //     cursor: pointer;
    //   }
    // }
  }
  
}


.BlaBlaFadeInFader {
  animation-name: fadeIn;
  animation-duration: $animDuration;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-delay: $delayTime;
  // &:nth-child(2) {
  //   animation-delay: (2 * $delayTime) + $hangTime;
  // }
  // &:nth-child(3) {
  //   animation-delay: (3 * $delayTime) + $hangTime;
  // }
  // &:nth-child(4) {
  //   animation-delay: (4 * $delayTime) + $hangTime;
  // }
  // &:nth-child(5) {
  //   animation-delay: (5 * $delayTime) + $hangTime;
  // }
  // &:nth-child(6) {
  //   animation-delay: (6 * $delayTime) + $hangTime;
  // }
  // &:nth-child(7) {
  //   animation-delay: (7 * $delayTime) + $hangTime;
  // }
  // &:nth-child(8) {
  //   animation-delay: (8 * $delayTime) + $hangTime;
  // }
  // &:nth-child(9) {
  //   animation-delay: (9 * $delayTime) + $hangTime;
  // }
  // &:nth-child(10) {
  //   animation-delay: (10 * $delayTime) + $hangTime;
  // }
}

div[data-purpose="itemFadeWrapper"]{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translate3d(0,0,0);
}

@keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}

