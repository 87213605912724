@import '../../mixins.scss';

.WhoContent {
  color: #000;
  font-family: "arboria", sans-serif;
  font-size: 1.2em; 
  margin: 0 30px;
  > p {
    margin-right: 30px;
  } 
  p {
    user-select: text;
  }
  display: grid;
  grid-template-columns: 50% 1fr;
  padding-bottom: 50px;
  @include for-breakpoint(md){
    display: flex;
    flex-direction: column;
  }
  @include for-breakpoint(sm){
    // margin-left: 0;
    padding: 0 0 30px;
    box-sizing: border-box;
    text-align: center;
    >p {
      margin: 15px 0;
    }
  }
}

.headshot {
  width: 260px;
  // float: left;
  margin: 10px 30px 30px 30px;
  @include for-breakpoint(md){
    margin-left: 0;
  }
  @include for-breakpoint(sm){
    margin: 0;
    margin-bottom: 10px;
  }
}

.headshotWrapper {
  float: left;
  @include for-breakpoint(md){
    text-align: center;
  }
}

.contactIcons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  @include for-breakpoint(sm){
    justify-content: center;
  }
  a{
    margin-right: 20px;
    cursor: pointer;
  }
  img, svg {
    width: 60px;
    height: 60px;
  }

}