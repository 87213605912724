.Stranger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #000;
  button {
    border: solid 1px rgba(255,255,255,0.5);
    background: transparent;
    color: rgba(255,255,255,0.7);
    transition: color 0.5s, border 0.5s;
    padding: 0.75em 2em;
    margin: 20px 1em;
    cursor: pointer;
    &:hover {
      color:rgba(255,255,255,1);
      border: solid 1px rgba(255,255,255,1)
    }
    &:first-child {
      // margin-left: -1em;
    }
  }
  >h1{
    font-family: kudryashev-d-excontrast-sans, sans-serif;
    font-size: 1.8rem;
    letter-spacing: 0.16rem;
    line-height: 1.8rem;
    text-align: center;
    padding: 0 1em;
    opacity: 0;
    animation: fadeIn 2s forwards;
    text-align: center;
  }
  >button {
    opacity: 0;
    animation: fadeIn 2s 2s forwards;
  }
}

@keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}
