@import '../../../mixins.scss';

.GalaxyBG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image:  url('/assets/img/galaxy1200.jpg');
  @include for-breakpoint(md) {
    background-image:  url('/assets/img/galaxy800.jpg');
  }
  
  background-size: 1200px 1200px;
  background-repeat: repeat;
  // background-position: center 10%
  background-image: 0 0;
  transform: translate3d(0,0,0)
}