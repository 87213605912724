@import '../../mixins.scss';

.BasePostPreview {
  width: 100%;
  color: #000;
  font-family: "arboria", sans-serif;
  >img{
    width: 100%;
    margin-bottom: 8px;
  }
  > p {
    padding: 0 0 10px 20px;
    margin-top: 0;
    font-size: 1.2em;
    user-select: text;
    @include for-breakpoint(md){
      padding: 0 20px 10px 20px;
    }
  }
}

.BasePostPreviewRowOne {
  display: flex;
  align-items: baseline;
  >h3 {
    padding: 0 20px;
    font-size: 1.6em;
    color: #000;
  }
  >span {
    font-size: 1em;
    opacity: 0.6;
    color: #000;
  }
  >span:nth-child(3){
    margin-left: 30px;
    font-size: .8em;
  }
}

.BasePostPreviewRowOneLinked{
  composes: BasePostPreviewRowOne;
  >h3{
    transition: color 0.7s;
    &:hover{
      color: #00aeef;
    }
  }
  >span {
    transition: color 0.7s;
    &:hover{
      color: #00aeef;
    }
  }
}

.expandPostCTA {
  cursor: pointer;
  color: #00aeef;
  font-size: 0.8em;
  padding: 2px 8px 6px 6px;
  padding-bottom: 6px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-left: 10px;
  text-align: center;
  transition: border-color 0.7s, color 0.7s;
  white-space: nowrap;
  &:hover {
    border-color: rgba(0,0,0,0.5);
  }
}

.pinnedIndicator {
  width: 25px;
  height: 25px;
  position: relative;
  left: 15px;
  top: 5px;
  opacity: 0.8;
}

.BasePostPreviewShareIcon {
  width: 27px;
  height: 27px;
  border: solid 1px #000;
  position: relative;
  left: 25px;
  top: 8px;
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.4;
  transition: border-color 0.7s, stroke 0.7s, fill 0.7s, opacity 0.7s;
  &:hover {
    border-color: #00aeef;
    stroke: #00aeef;
    fill: #00aeef;
    opacity: 1;
  }
}

.BasePostPreviewShareBar{
  background: #00aeef;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: translateX(-88%);
  input {
    z-index: 1000;
    display: block;
    background: #fff;
    position: relative;
    border: solid 1px #000;
    padding: 6px;
    margin: 10px;
    margin-right: 2px;
  }
  svg {
    background: #fff;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    cursor: pointer;
  }
}

.clipboardCopiedIndicator {
  display: block;
  width: 100%;
  position: absolute;
  top: -1em;
  background: #ec008c;
  text-align: center;
  font-weight: 700;
}