.diceWrapper {
  z-index: 11;
  position: relative;
  img {
    z-index: 3;
    width: 100vw;
    max-width: 1300px;
    height: auto;
    position: relative;
  }
  canvas {
    z-index: 3;
    -webkit-tap-highlight-color: transparent;
  }

  > svg {
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    > polyline {
      stroke: none;
      fill: none;
      cursor: zoom-in;
      pointer-events: fill;
    }
  }
}

.canvasWrapper {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.canvasWrapperHover {
  composes: canvasWrapper;
  cursor: grab;
}
.canvasWrapperClick {
  composes: canvasWrapper;
  cursor: grabbing;
}
.diceOverlay {
  background-color: rgba(0,0,0,0.81);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: url('../../../assets/cursor/goBackCursor20.png'), auto;
}



