@import '../../../mixins.scss';
.KeyGiverWrapper {
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: flex-start;
  padding-bottom: 5%;
  // padding-top: 5%;
  // overflow: auto;
  overflow-y: scroll;
  width: calc(100vw + 17px);
  height: 100vh;
  overflow-y: scroll;
  @include for-breakpoint(md) {
    width: 100%;
    // height: unset;
  }
  // padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  // box-sizing: content-box; /* So the width will be 100% + 17px */
  // div[data-utility="responseButtonsWrapper"] {
  //   margin: 0.5rem 0;
  // }
}


.confirmDumpKeyModal {
  position: fixed;
  width: calc(100vw + 17px);
  height: 100%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  h2 {
    font-weight: 300;
    font-size: 1.7em;
    margin-bottom: 0.5em;
  }
  P {
    padding: 0;
    margin: 0;
    font-size: 1.2em;
  }
  > div:first-child { // wrapper with border
    width: 70%;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: solid 1px rgba(255,255,255,0.5);
    z-index: 2;
    p {
      width: 100%;
      text-align: center;
    }
    > div {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 1em;
      > div {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 2.1em;
        left: 50%;
        transform: translateX(-50%);
        span {
          opacity: 0;
          &:first-child {
            margin-bottom: 1em;
          }
        }
      }
      > p {
        margin-top: 1em;
      }
      > video {
        position: absolute;
        // opacity: 0.8;
        opacity: 0;
        // z-index: 9;
      }
      > svg {
        margin-right: 2em;
      }
      > img {
        width: 315px;
      }
      > button {
        margin-left: 4em;
        margin-bottom: 3em;
        margin-top: 2em;
        border: solid 1px rgba(255,255,255,0.5);
        background: transparent;
        color: rgba(255,255,255,0.7);
        transition: color 0.5s, border 0.5s;
        padding: 0.75em 2em;
        // margin: 0 1em;
        cursor: pointer;
        &:hover {
          color:rgba(255,255,255,1);
          border: solid 1px rgba(255,255,255,1)
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  @include for-breakpoint(md) {
    width: 100%;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 10px;
    h2 {
      font-size: 2.2em;
    }
    p {
      font-size: 1.5em;
      // margin: 0 40px;
      margin: 30px 0 0 0;
      display: block;
      width: 100%;
    }
    > div:first-child { 
      width: 100%;
      border: 0;
      & > div {
        display: grid;
        video {
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0) scaleX(-1);
          height: 670px;
          @include for-breakpoint(md){
            transform: translate3d(-50%, -33%, 0) scaleX(-1);
          }
        }
        &:nth-of-type(2){
          width: 315px;
          button {
            width: 315px;
            margin: 0;
            font-size: 1.2rem;
            padding: 19px 0;
            margin-bottom: 1em;
            &:first-of-type{
              margin-top: 1em;
            }
          }
        }
        svg {
          margin-right: 0;
          margin-bottom: 2em;
        }
        & > div {
          
        }
      }
    }
    > div:nth-of-type(2) {
      width: 315px;
      button {
        min-width: 100%;
        margin: 0;
      }
    } 
  }
}

.modalBackdrop {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.9);
  z-index: 1;
}