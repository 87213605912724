@mixin for-breakpoint($breakpoint) {
  @if $breakpoint == xl {
    @media (max-width:1536px) {@content;}
  }
  @else if $breakpoint == lg {
    @media (max-width:1200px) {@content;}
  }
  @else if $breakpoint == md {
    @media (max-width:900px) {@content;}
  }
  @else if $breakpoint == sm {
    @media (max-width:600px) {@content;}
  }
  @else if $breakpoint == xs {
    @media (max-width:480px) {@content;}
  }
}