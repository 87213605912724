.backplate {
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.7);
  z-index: 11;
  position: relative;
  cursor: zoom-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardWrap {
  width: 200vw;
  aspect-ratio: 1920/1097;
}


.transformWrap{
  z-index: 99999;
  position: absolute;
  transform: translate3d(0,0,0);
  width:100vw;
  height: 100vh;
}

.cardActualWrap {
  z-index: 99999;
  position:relative;
  transform:translate3d(0,0,0);
  width:100vw;
  height:100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardActual {
  width: 85vw;
  height: auto;
  max-width: 1060px;
}