

.SimpleModal {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.9);
  justify-content: center;
  align-items: center;
  > div { //content container
    padding: 20px;
    background: #fff;
    border-radius: 13%;
  }
}

