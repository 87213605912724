@import '../../../mixins.scss';
.BlaBlaDialogueRendererWrapper{
  align-items: bottom;
}

.BlaBlaWrapper {
  width: 80%;
  // padding: 1em;
  max-width: 1100px;
  padding-bottom: 3rem;
  padding-top: 5rem;
}

.BlaBlaDIalogueOutputWrapper {
  div[data-purpose="itemFadeWrapper"] {
    margin-top: 1.5em;
    &:first-child{
      margin-top: 0;
    }
  }
  font-family: kudryashev-d-excontrast-sans, sans-serif;
  font-size: 2.3rem;
  letter-spacing: 0.16rem;
  line-height: 2.8rem;
}


.keyDispenseWrapper {
  // margin-top: -8%;
  // margin-top: 5%;
}

.keyDispenseTopRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  
  > div {
    display: flex;
    flex-direction: column;
    &:first-child {
      margin-right: 5em;
      // margin-bottom: 1.9em;
    }
    > span {
      font-size: 0.9em;
      
    }
    > img {
      height: 315px;
      width: 315px;
      margin-top: 0.7em
    }
    > button {
      border: solid 1px rgba(255,255,255,0.5);
      background: transparent;
      color: rgba(255,255,255,0.7);
      transition: color 0.5s, border 0.5s;
      padding: 0.75em 2em;
      margin:0.7em 0;
      font-size: 0.4em;
      cursor: pointer;
      &:hover {
        color:rgba(255,255,255,1);
        border: solid 1px rgba(255,255,255,1)
      }
    }
  }
  @include for-breakpoint(md) {
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > div {
      &:first-child {
        margin-right: 0;
        margin-bottom: 5rem;
      }
    }
  }
}

.keyWrapper {
  height: 315px;
  width: 315px;
  margin-top: 0.7em
}

.keyDispenseBottomRow {
  margin-top: -1.5rem;
  padding-bottom: 2rem;
  > input {
    font-size: 1.9rem;
    box-sizing: border-box;
    width: 90%;
    display: block;
    border-radius: 4px;
    margin: 0.3em auto -1rem auto;
    padding: 0.4em 0.5em;
  }
  i {
    opacity: 0.5;
    font-size: 0.6em;
  }
}

.keyInfoText {
  a {
    color: #ff00ae;
    opacity: 0.8;
    text-decoration: none; 
    &:hover {
      opacity: 1;
    }
  }
  em {
    color: #00baff;
  }
}

.returnLink {
  display: block;
}

.keyDispenseTextRowWrapper{
  // border: solid 1px #f00;
  width: 100%;
  display: block !important;
}
.keyDispenserResponsesWrapper{
  margin-top: 0 !important;
}


.KGConvoModalRoot {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  z-index: 9;
}