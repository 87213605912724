@import '../../../mixins.scss';
.HubNavWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  // height: auto;
  z-index: 4;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 1fr;
}

.TLDCBadge {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include for-breakpoint(md) {
    position: absolute;
    top: 46px;
    left: 50%;
    transform: translateX(-50%);
  }
  @include for-breakpoint(sm) {
    left: 10px;
    transform: unset;
  }
  p{
    font-family: arboria, sans-serif;
    font-weight: 400;
    font-style: normal;
    // opacity: 0.85;
    margin: 0;
    // box-sizing: border-box;
    font-size: 1rem;
    span:first-child {
      color: #ff9000;
      padding-right: 0.5rem;
      font-size: 1.1rem;
      opacity: 0.9;
    }
    span:last-child {
      opacity: 0.5;
      background: rgba(0,0,0,0.55);
      border-radius: 5px;  
      padding: 0.25rem 0.5rem;
    }
    // transform: translateY(-25%);
  }
}

.HubNav {
  display: flex;
  background: #fff;
  display: inline-flex;
  height: fit-content;
  grid-column: 2;
  // position: fixed;
  // top: 0;
  // left: 50%;
  // transform: translateX(-50%);
  z-index: 4;
  padding: 0;
  border-radius: 0 0 5px 5px;
  a {
    padding: 0.1rem 1rem 0.6rem 1rem;
    font-family: arboria, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    color: #000;
    font-size: 1.4rem;
    &:before {
      content: '';
      height: 100%;
      width: 1px;
      background: rgba(0,0,0,0.25);
      display: block;
      position: absolute;
      right: calc(100% + 5px);
    }
    &:first-child {
      &:before{
        display: none;
      }
      margin-left: 0.75rem;
      margin-right: 5px;
    } 
    &:last-child {
      margin-right: 0.75rem;
      margin-left: 5px;
    }
    transition: transform 0.25s, color 0.5s;
    transform: scale(1);
    &:hover {
      // transform: scale(1.05);
      color: #009ae7;
    }
  }
}


.currentPageLink {
  opacity: 0.5;
}