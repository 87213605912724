.ReturnWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 11%;
  color: rgba(255,255,255,0.8);
  > h1 {
    font-weight: 300;
    margin: 0;
    // margin-top: -20%;
  }
  > p {
    margin: 20px 0 3em 0;
    font-size: 1.25em;
  } 
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-bottom: 25px;
  max-width: 500px;
  button {
    // border-radius: 10px;
    background: none;
    outline: none;
    border: none;
    // border: solid 1px #000;
    // border: solid 1px rgba(255,255,255,1);
    // opacity: 0.9;
    // padding: 0.5em ;
    margin-left: 1em;
    width: 33.3%;
    cursor: pointer;
    // transition: opacity 2s;
    img {
      width: 100%;
      height: auto;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}

.inputs {
  min-width: 29em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  textArea {
    border-radius: 3px;
  }
  button {
    width: 100%;
    margin-top: 0.5em;
    background: none;
    border: solid 1px #fff;
    color: #fff;
    opacity: 0.7;
    padding: 0.5em 0;
    cursor: pointer;
    font-weight: 300;
    transition: opacity 2s;
    &:hover {
      opacity: 1;
    }
  }
  img {
    width: 100px;
    display: block;
    margin: 2em auto 1em;
  }
}

.uploadView {

}


.response {
  color: #cb0039;
  opacity: 0;
  animation: responseFadeIn 0.4s;
  animation-fill-mode: forwards;
  h2 {

  }
}

@keyframes responseFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.userVid {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.camView {
  // border: solid;
  // :global(.scan-region-hightlight) {
  //   border: solid 30px #f00;
  // }
  position: relative;
  overflow: hidden;
  > div {
    border: solid 300px rgba(0,0,0,0.7);
    transform: translate3d(-300px,-300px,0);
    // box-sizing: border-box;
    > svg {
      display: none;
    }
  }
  
}