.wrapper {
  width: 100vw;
  height: 100vh;
  padding: 0; 
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  // background: rgba(0,0,0,0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  // display: block;
}

.overlay {
  width: 100%;
  height: 100%;
  z-index: -1;
  // background: rgba(0,0,0,0.8);
  position: absolute;
  top: 0;
  left: 0;
}